// polyfills
import 'core-js/modules/es7.object.values';
import 'core-js/modules/es7.array.includes';
import 'core-js/modules/es6.string.includes';
import 'es6-promise/auto';
import 'whatwg-fetch';

// preload common dependencies
import * as React             from 'react';
import { render }             from 'react-dom';
import 'recompose';
import 'classnames';
import './services/SessionService';

import loadScript             from './helpers/loadScript';
import {
    restoreNavigation,
    confirmClosePortlet
}                             from './helpers/navigation';
import {
    LogActivityFormChunk,
    AddLeadFormChunk,
    AddContactFormChunk,
    NumbersManagerChunk,
    TranslationsManagerChunk
}                             from './helpers/chunks';
import { clearForms }         from './helpers/forms';
import { connectSharedState } from './sharedState';
import {
    SharedStateContextTypes,
    SharedStateContext
}                             from './types/SharedTypes';
import {
    fetchTranslations,
    translate,
    translationsCache
}                             from './services/TranslationService';
import {
    compose,
    withState,
    branch,
    renderNothing,
    mapProps,
    withContext,
    lifecycle
}                             from 'recompose';

// TS complains about css import
import './styles/FormStyle.scss';
import './styles/_message.scss';
import './styles/_buttons.scss';

const EMPTY_OBJ = {};
const STFLO_FORM_CLASS = '.stfloCallFormWidget__Form';

const portletWrapperComponent = (targetElement, enhancers = []) => compose(
    connectSharedState,
    withState('portletInstance', 'setPortletInstance', true),
    withState('loading', 'setLoading', true),
    withState('error', 'setError', undefined),
    withContext(SharedStateContextTypes, ({ sharedState, sharedStateDispatchers }): SharedStateContext => ({ sharedState, sharedStateDispatchers })),
    mapProps(({ setPortletInstance, ...ownProps }: PortletInstanceProps): PortletMainProps => ({
        ...ownProps,
        translate,
        closePortlet(closePortletConfig?: ClosePortletConfig) {
            if (closePortletConfig && closePortletConfig.confirmMessage) {
                if (!confirmClosePortlet(closePortletConfig.confirmMessage)) {
                    return false;
                }
            }

            // DO NOT innerHTML = '' in order to run componentDidUnmount callbacks
            if (closePortletConfig && closePortletConfig.enableLogActivity === true) {
                const logActivityButton = document.querySelector('#logActivityButton');
                if (logActivityButton && logActivityButton.classList.contains('click-disabled')) {
                    logActivityButton.classList.remove('click-disabled');
                }
            }

            if (closePortletConfig && closePortletConfig.resetSearch === true) {
                const searchInput = (document.getElementById('portletSearchInput') as HTMLInputElement);
                if (searchInput) {
                    searchInput.value = '';
                }
            }

            clearForms(STFLO_FORM_CLASS);
            restoreNavigation();
            setPortletInstance(false);
            targetElement.innerHTML = '';
        }
    })),
    lifecycle<{}, {}>({
        async componentDidMount() {
            try {
                if (this.props.language && !translationsCache) {
                    await fetchTranslations(this.props.language);
                }
            } catch (error) {
                this.props.setError({ message: ['Error - Please try refreshing the page.']}); // Format of error component
            } finally {
                this.props.setLoading(false);
            }
        }
    }),
    branch(({ portletInstance }: PortletInstanceProps) => !portletInstance, renderNothing),
    ...enhancers,
);

async function renderPortlet(portlet: Portlet, selector: string | Element, props: Partial<PortletInstanceProps> = EMPTY_OBJ, enhancers?) {
    const target  = typeof(selector) === 'string' ? document.querySelector(selector) : selector;
    const Portlet = portletWrapperComponent(target, enhancers)(portlet);

    render((
        <Portlet {...props} />
    ), target);
}

(async function createPortletHandlers(global: Window) {

    // for non-fetch browsers
    if (!global.hasOwnProperty('fetch')) {
        await loadScript(`${STFLO_ENV_VARS.PUBLIC_URL.split('bundle.js').join('')}polyfills.js`);
    }

    Object.assign<Window, PortletHandlersNamespace>(window, {
        __STFLO__PORTLET: {
            // Please read https://webpack.js.org/guides/code-splitting/ before touching it :)
            // Please do not repeat webpackChunkName
            async logActivity(selector: string, props: object = EMPTY_OBJ) {
                const { default: LogActivity }: PortletLoad = await LogActivityFormChunk();
                const target = document.querySelector(selector);

                if (!target.innerHTML.trim().length) {
                    renderPortlet(LogActivity, target, props);
                }
            },
            async addLead(selector: string, props: object = EMPTY_OBJ) {
                const { default: AddLead }: PortletLoad = await AddLeadFormChunk();
                const target = document.querySelector(selector);

                if (!target.innerHTML.trim().length) {
                    renderPortlet(AddLead, target, props);
                }
            },
            async addContact(selector: string, props: object = EMPTY_OBJ) {
                const { default: AddContact }: PortletLoad = await AddContactFormChunk();
                const target = document.querySelector(selector);

                if (!target.innerHTML.trim().length) {
                    renderPortlet(AddContact, target, props);
                }
            },
            async numbersManager(selector: string, props: object = EMPTY_OBJ) {
                const { default: NumbersManager }: PortletLoad = await NumbersManagerChunk();
                const target = document.querySelector(selector);

                if (!target.innerHTML.trim().length) {
                    renderPortlet(NumbersManager, selector, props);
                }
            },
            async translationsManager(selector: string, props: object = EMPTY_OBJ) {
                const { default: TranslationsManager }: PortletLoad = await TranslationsManagerChunk();
                const target = document.querySelector(selector);

                if (!target.innerHTML.trim().length) {
                    renderPortlet(TranslationsManager, selector, props);
                }
            }
        }
    });
}(window));
