export const preventNavigation = (e) => {
    const navigation = false;

    e.preventDefault();
    e.returnValue = navigation; // Gecko, Trident, Chrome 34+ implementation
    return navigation;
};

// keep the user from navigating away when a call is in progress
export const lockNavigation = () => {
    window.addEventListener('beforeunload', preventNavigation);
};

// remove the navigation lock when a call has been saved or the portlet is closed
export const restoreNavigation = () => {
    window.removeEventListener('beforeunload', preventNavigation);
};

export const confirmClosePortlet = message => window.confirm(message);
