export default function loadScript(url: string) : Promise<HTMLScriptElement> {
    const script = document.createElement('script');

    return new Promise((resolve, reject) => {
        Object.assign(script, {
            type: 'text/javascript',
            onload: () => resolve(),
            onerror: reject,
            src: url
        });
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}
